<template>
<div class="rtl">
  <div style="width:100%;padding: 20px">
    <span style="color:black;font-size:15px">مدیریت معاملات سریع</span>
  </div>
  <select class="col-5" v-model="selected" @click="getFastExchangePrice(selected)">
    <option value="" selected disabled hidden >لطفا انتخاب کنید</option>
    <option  v-for="(asset,index) in assets.filter((item)=>item.symbol != 'TOMAN')" :key="index"  :value="asset.id" >{{asset.symbol}}</option>
  </select>
  <div  style="text-align: center;color:black;font-size: 12px;padding: 5px;padding-bottom: 10%" >
    <center>
      <div class="col-12 divRow" style="height: 40px;color: black; transition: 0.3s;direction: ltr;font-size: 13px;padding-top: 9px">
        <div class="row">
          <div class="col-3"></div>
          <div class="col-2">قیمت خرید</div>
          <div class="col-2">قیمت فروش</div>
          <div class="col-2">نماد</div>
          <div class="col-1">نام انگلیسی</div>
          <div class="col-1">نام بازار</div>
          <div class="col-1">آی دی</div>
        </div>
      </div>
      <div class="col-12 divRow" style="height: 300px;overflow: auto;color: black; transition: 0.3s;direction: ltr;font-size: 13px;margin-top: 1%" v-if="asset != ' '">
        <div class="row enterReport" style="height: 40px;padding-top: 5px;padding-bottom: 8px;margin-bottom: 10px">
          <p></p>
          <div class="col-3" >
            <v-btn v-if="asset != ''"  style=" margin-left: 2px"
                    @click="showBuyModal()" >ویرایش خرید</v-btn>
            <v-btn v-if="asset != ''"  style=" margin-left: 2px"
                    @click="showSellModal()" >ویرایش فروش</v-btn>
          </div>
          <div class="col-2"><span>{{fastBuy}}</span></div>
          <div class="col-2"><span>{{fastSell}}</span></div>
          <div class="col-2"><span>{{asset.symbol}}</span></div>
          <div class="col-1"><span>{{asset.englishTitle}}</span></div>
          <div class="col-1"><span>{{asset.persianTitle}}</span></div>
          <div class="col-1"><span>{{asset.id}}</span></div>

        </div>
      </div>
    </center>
  </div>
  <Popup :show.sync="sellShow" width="600px">
    <template slot="body">
      <v-toolbar
          color="primary"
          dark
          class="rtl"
      >ویرایش</v-toolbar>
      <v-card-text class="rtl">
        <slot name="body"></slot>
                  <div class="row" style="margin-top:20px">
                    <div class="col-2" style="margin-right: 30px">قیمت فروش</div>
                    <input v-model="fastSell" class="form-control col-5">
                  </div>

                  <div style="margin-right: 40%">
                    <v-btn   @click="SetFastExchangePrice(asset.id,'Sell',fastSell)">ویرایش قیمت </v-btn>
                  </div>

                  <hr style="border-top-color: white">

      </v-card-text>
    </template>
  </Popup>
  <Popup :show.sync="buyShow" width="600px">
    <template slot="body">
      <v-toolbar
          color="primary"
          dark
          class="rtl"
      >ویرایش</v-toolbar>
      <v-card-text class="rtl">
        <slot name="body"></slot>
                  <div class="row" style="margin-top:20px">
                    <div class="col-2" style="margin-right: 30px">قیمت خرید</div>
                    <input v-model="fastBuy" class="form-control col-5">
                  </div>

                  <div style="margin-right: 40%">
                    <v-btn  @click="SetFastExchangePrice(asset.id,'Buy',fastBuy)">ویرایش قیمت </v-btn>
                  </div>

                  <hr style="border-top-color: white">
      </v-card-text>
    </template>
  </Popup>
</div>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";
export default {
name: "FastExchangePrice",
  components :{
    Popup
  },
  data(){
  return{
    assets : [],
    fastBuy : '',
    fastSell : '',
    asset : '',
    selected : '',
    sellShow : false,
    buyShow : false,
    search : '',
    headers: [
      {text: 'آی دی', value: 'id'},
      {text: 'نام بازار', value: 'persianTitle'},
      {text: 'نام انگلیسی', value: 'englishTitle'},
      {text: 'نماد', value: 'symbol'},
      {text: 'قیمت فروش', value: 'fastSell'},
      {text: 'قیمت خرید', value: 'fastBuy'},
      {text: 'عملیات', value: 'operations'},
    ],
  }
  },
  mounted() {
    this.getAssets();
  }
  ,
  methods:{
    getAssets()
    {
      axiosApi().post('/api/Manage/v1/Asset/GetList')
        .then(({data}) => {
          if (data['isSuccess'] === true)
            this.assets = data.data
        })
    },
    getAsset(id)
    {
      axiosApi().post('/api/Manage/v1/Asset/Get',{
        id : id
      })
        .then(({data}) => {
          if (data['isSuccess'] === true)
            this.asset = data.data
        })
    },
    getFastExchangePrice(id)
    {
      if (id === '')
        return false

      axiosApi().post('/api/Manage/v1/FastExchange/GetFastExchangePriceInToman',{
        assetId: id
      }).then(({data})=> {
        if (data['isSuccess'])
        {
          this.getAsset(id)
          this.fastBuy = data.data.fastExchangeBuyPriceInToman
          this.fastSell = data.data.fastExchangeSellPriceInToman
        }
      })
    },
    SetFastExchangePrice(id,type,priceInToman)
    {
      axiosApi().post('/api/Manage/v1/FastExchange/SetFastExchangePriceInToman',{
        assetId: id,
        orderType: type,
        priceInToman: priceInToman
      }).then(({data})=>{
        if (data['isSuccess']) {
          Vue.$toast.open({
            message: 'تغییر قیمت  با موفقیت انجام گردید ',
            type: 'success',
            position: 'top-right'
          })
        }
      })
    },
    showBuyModal()
    {
      this.buyShow = true
    },
    showSellModal()
    {
      this.sellShow = true
    },

  }
}
</script>

<style scoped>

</style>
